<template>
	<div id="page-dashboard">
		<div class="graficos">
			<div class="col-12 topo">
				<div class="row row-topo">
					<div class="col-3 titulo">
						<p>DASHBOARD <v-icon>fas fa-chart-bar</v-icon></p>
					</div>
					<div class="col-9 filtros">
						<v-form ref="form_filtros">
							<v-icon>fas fa-filter</v-icon>
							<v-select
								:items="setores"
								item-text="name"
								item-value="id"
								v-model="setores_selecionado"
								label="Setor"
								placeholder="Setor"
								background-color="white"
								hide-details
								outlined
								@change="changeAtendente()"
								style="width: 20%; font-size: 15px; font-weight: 600"
							/>							
							<v-select
								:items="atendente"
								item-text="name"
								item-value="id"
								v-model="atendente_selecionado"
								label="Atendente"
								placeholder="Atendente"
								background-color="white"
								hide-details
								outlined
								style="width: 22%; font-size: 15px; font-weight: 600"	
							/>
							<v-menu
								v-model="menu1"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="auto"
								>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="data_de_formatada"
										label= "De"
										persistent-hint
										prepend-icon="mdi-calendar"
										hide-details
										readonly
										v-bind="attrs"
										v-on="on"
										style="font-size: 14px; font-weight: 600"			
									></v-text-field>
								</template>
								<v-date-picker
									v-model="data_de"
									no-title
									color="#11263C"
									locale="pt-br"
									@input="menu1 = false"
								></v-date-picker>
							</v-menu>
							<v-menu
								v-model="menu2"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								max-width="290px"
								min-width="auto"
								>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="data_ate_formatada"
										label= "Até"
										persistent-hint
										prepend-icon="mdi-calendar"
										hide-details
										readonly
										v-bind="attrs"
										v-on="on"
										style="font-size: 14px; font-weight: 600"			
									></v-text-field>
								</template>
								<v-date-picker
									v-model="data_ate"
									no-title
									color="#11263C"
									locale="pt-br"
									@input="menu2 = false"
								></v-date-picker>
							</v-menu>							
							<v-btn class="primary-button" small @click="searchFiltro">Filtrar </v-btn>
							<v-btn class="primary-button" small @click="limparFiltro"> 
								Limpar
							<i class="fas fa-filter" style="color: #FFFFFF;"></i>
							</v-btn>
						</v-form>
					</div>					
				</div>
			</div>
			<div class="row">
				<div class="col-12 grafico-atendimentos">				
					<Chart
						:id="'graficoTotalAtendimentos canvas'"
						:height="50"
						:type="'line'"
						:title="'Total de Atendimentos'"
						:labels="['Jan', 'Fev', 'Mar' , 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']"
						:data="dados"
						:backgroundColor="'#F4C430'"
						:borderColor="'#F4C430'"
						:borderWidth="4"
						:primarycolor="'#F4D728'"
						:secondarycolor="'#F4C430'"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-4 grafico-percentual">
					<Chart
						:id="'graficoPercentual'"
						:type="'doughnut'"
						:title="'Percentual'"
						:labels="['Abertos nos últimos dias', 'Aguardando o Cliente', 'Fechamentos em Potencial' , 'Atendimento Online']"
						:data="[novos_ativos, aguardando_ativos, fechamentos_ativos, atendimentos_ativos, chamados_realizados]"
						:backgroundColor="['#F7D728', '#EBC438', '#FBEBBA', '#FFF7CD']"
						:borderWidth="5"
						:chamados_realizados="chamados_realizados"
                		ref="grafico"
					/>					
				</div>
				<div class="col-3 legendas-percentual">
					<div class="col-12 div-abertos">
						<p class="abertos"><span>Abertos nos últimos dias</span></p>
						<p class="abertos-ativos">{{novos_ativos}} Abertos/Últimos 7 dias</p>
					</div>
					<div class="col-12 div-aguardando">
						<p class="aguardando"><span>Aguardando o cliente</span></p>
						<p class="aguardando-ativos">{{aguardando_ativos}} Aguardando Cliente</p>
					</div>
					<div class="col-12 div-fechamentos">
						<p class="fechamentos"><span>Fechamentos em potencial</span></p>
						<p class="fechamentos-ativos">{{fechamentos_ativos}} FP</p>
					</div>
					<div class="col-12 div-atendimento">
						<p class="atendimento"><span>Atendimento online</span></p>
						<p class="atendimento-ativos">{{atendimentos_ativos}} Ativos</p>
					</div>
				</div>
				<div class="col-5 grafico-porcentagem-ativa">
					<v-card-title>
						<span class="titulo-grafico"> Porcentagem Ativa </span>
					</v-card-title>

					<template>
						<p class="total-porcentagem"><span>{{total_porcentagem_ativa}}</span> Total</p>
						<v-progress-linear
							:value="total_porcentagem_ativa"
							height="20"
							color="#F4C430"
							rounded
						>
						</v-progress-linear>
						<div class="col-12 legendas">
							<div class="row">
								<div class="col-7 atendimentos-novos">
									<p class="novos"><span>Novos Atendimentos</span></p>
									<p class="novos-ativos">{{novos_atendimentos}} atendimentos</p>
								</div>
								<div class="col-5 atendimentos-fechados">
									<p class="fechados"><span>Fechados</span></p>
									<p class="fechados-ativos">{{fechados_ativos}} fechados</p>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa Chart
    import Chart from '@/components/Chart.vue'
	// importa o plugin de request 
	// import Request from '@/plugins/request'

	// exporta o componente
	export default {
		// nome do componente
		name: 'PageDashboard',
		// componentes filhos
		components: {
            Chart
		},
		// dados do componente
		data: () => ({
			data_de: new Date().toISOString().substr(0, 10),			
			data_ate: new Date().toISOString().substr(0, 10),			
			menu1: false,			
			menu2: false,
			//variavel para os setores
			setores: [],	
			//variavel para armazenar o setor selecionado
			setores_selecionado: [],		
			//variavel para os atendentes
			atendente: [],	
			//variavel para armazenar o atendente selecionado		
			atendente_selecionado: [],
			//variavel para puxar os dados do total de atendimentos de cada mes
			atendimentos: '',
			//variavel para  armazenar os dados do total de atendimentos de cada mes:
			dados: [],
			//variavel para os dados do total de chamados
			chamados_realizados: '',
			//variavel para os novos atendimentos
			novos_ativos: '',
			//variavel para os atendimentos que estão aguardando retorno
			aguardando_ativos: '',
			//variavel para os atendimentos com potencial de fechamento
			fechamentos_ativos: '',
			//variavel para os atendimentos ativos
			atendimentos_ativos: '',
			//variavel para a porcetagem ativa de determinado atendente
			total_porcentagem_ativa: null,
			//variavel para os novos atendimentos de determinado atendente
			novos_atendimentos: null,
			//variavel para os atendimentos fechados de determinado atendente
			fechados_ativos: null,
			//variavel para os campos do filtro
			filters: {
				end_date: '',
				start_date: '',
				setores: '',
				atendente: '',
			},
		}),
		computed: {
			data_de_formatada () {
				return this.formatDate(this.data_de)
			},
			data_ate_formatada () {
				return this.formatDate(this.data_ate)
			},
		},
		watch: {
			'$store.getters.company_uuid':{
				handler (){
					this.init()
				},
			},
			'store.getters.workspace_uuid': {
				handler (){
					this.init()
				},
			},
		},
		// funções deste componente
		methods: {
			async init(){
				// função para pegar os setores
				await this.getSetores()

				
			},
			async getFilter(){
				// faz um dispatch para uma action no vuex para pegar os dados do filtro
				var resp = await store.dispatch('getFilters', this.filters)
				// atribui os dados que vem do back a partir do filtro
				this.total_porcentagem_ativa = await resp.retorno.user_rooms.length
				// atribui os dados que vem do back a partir do filtro
				this.novos_atendimentos = await resp.retorno.user_news.length
				// atribui os dados que vem do back a partir do filtro
				this.fechados_ativos = await resp.retorno.user_closeds.length
				// atribui os dados vindos do back a partir do filtro
				this.atendimentos = await resp.retorno.Meses
				//atribui o dado vindo do back a variavel local		
				let meses = this.atendimentos
				//estrutura de repetição
				this.dados = []
				for(let i = 0; meses.length > i; i++){
					//cria uma matriz e atribui a dados
					this.dados.push(Number(meses[i].count));
				}

				// atribui os dados que vem do back a partir do filtro
				this.chamados_realizados = await resp.retorno.user_total_chamados.length
				// atribui os dados que vem do back a partir do filtro
				this.novos_ativos = await resp.retorno.user_ultima_semana.length	
				// atribui os dados que vem do back a partir do filtro		
				this.aguardando_ativos = await resp.retorno.user_aguardando_cliente.length
				// atribui os dados que vem do back a partir do filtro
				this.fechamentos_ativos = await	resp.retorno.user_fechamento_potencial.length
				// atribui os dados que vem do back a partir do filtro	
				this.atendimentos_ativos = await resp.retorno.user_atendimentos_ativos.length
			},
			// função para coletar os setores
			async getSetores(){
				// faz um dispatch para uma action no vuex para pegar os setores
				var resp = await store.dispatch('getSetoresSelect')
				// caso o status seja 200 (deu certo)
				if(resp.status == 200){
				// atribui os dados vindos do back a variavel setores
				this.setores = await resp.data.departments
				}								
			},
			// função para coletar os atendentes
			async changeAtendente(){
				//faz um dispatch para uma action no vuex para pegar os atendentes
				var resp = await store.dispatch('getUsuarioSelect')
				//caso o status seja 200 (deu certo)
				if(resp.status == 200){
					//atribui os dados vindos do back a varíavel local
					this.atendente = await resp.data.users
				}
			},
			formatDate (date) {
				if (!date) return null

				const [year, month, day] = date.split('-')
				return `${day}/${month}/${year}`
			},
			// função de filtro da nossa dashboard 
			async searchFiltro(){
				// coloca o componente como loading
				this.loading = true
				// atribui o setor selecionado
				this.filters.setores = this.setores_selecionado
				// atribui o atendente selecionado
				this.filters.atendente = this.atendente_selecionado
				// atribui a data final selecionada
				this.filters.end_date = this.data_ate_formatada
				//atribui a data inicial selecionada
				this.filters.start_date = this.data_de_formatada				
				//puxa a função com os dados vindos do back
				this.getFilter()
				// retira o loading do componente
				this.loading = false
			},
			// função para limpar os campos do filtro
			limparFiltro(){
				// limpa os dados locais
				this.atendente_selecionado = ''
				this.setores_selecionado = ''
				this.data_de = new Date().toISOString().substr(0, 10)			
				this.data_ate = new Date().toISOString().substr(0, 10)
				this.dados = ''
				this.chamados_realizados = ''				
				this.novos_ativos = ''
				this.aguardando_ativos = ''
				this.fechamentos_ativos = ''
				this.atendimentos_ativos = ''
				this.total_porcentagem_ativa = null
				this.novos_atendimentos = null
				this.fechados_ativos = null
			},
		},
		// funções que rodam quando o componente é montado
		mounted(){
			// função de início do componente
			this.init()
		},
	}
</script>

<style lang="scss">
	#page-dashboard{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;

		.graficos{
			background-color: #fff;
			border-radius: 10px;
			width: 100%;
			.topo{
				padding-bottom: 0;
				.titulo{
					padding-bottom: 0;
					p{
						color: #11263C;
						font-weight: 500;
						font-size: 14px;
						line-height: 58px;

						svg{
							font-size: 19px;
							margin: 0 4px;
							color: #040d16;
						}
						span{
							font-weight: 500;
							color: #D0D1D2;
						}
					}
				}
				.filtros{
					form{
						display: flex;
						flex-wrap: nowrap;
						justify-content: space-between;
						align-items: center;
						svg{
							font-size: 15px;
							color: $quaternarycolor;
						}
						.v-input{
							display: flex;
							flex-wrap: nowrap;
							justify-content: space-between;
							align-items: center;
							padding: 0 10px;
						}
					}
				}
			}
			.grafico-atendimentos{
				padding-top: 0;
			}
			.v-card__title{
				padding-left: 0;
			}
			.titulo-grafico{
				font-weight: 700;
				color: $quaternarycolor;
			}
			.legendas-percentual{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding: 50px 0;
				font-size: 12px;
				.div-abertos{
					padding-left: 0;
					.abertos{
						position: relative;
						color: #949494;
						font-size: 14px;
						margin: 0;
						span{
							margin-left: 40px;
						}
						&:before{
							content: '';
							width: 30px;
							height: 6px;
							position: absolute;
							background-color: #F7D728;
							border: 1px solid #F7D728;
							border-radius: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
						@media (max-width: 1300px){
							font-size: 12px;
						}
					}
				}
				.div-aguardando{
					padding-left: 0;
					.aguardando{
						position: relative;
						color: #949494;
						font-size: 14px;
						margin: 0;
						span{
							margin-left: 40px;
						}
						&:before{
							content: '';
							width: 30px;
							height: 6px;
							position: absolute;
							background-color: #EBC438;
							border: 1px solid #EBC438;
							border-radius: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
						@media (max-width: 1300px){
							font-size: 12px;
						}
					}
				}
				.div-fechamentos{
					padding-left: 0;
					.fechamentos{
						position: relative;
						color: #949494;
						font-size: 14px;
						margin: 0;
						span{
							margin-left: 40px;
						}
						&:before{
							content: '';
							width: 30px;
							height: 6px;
							position: absolute;
							background-color: #FBEBBA;
							border: 1px solid #FBEBBA;
							border-radius: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
						@media (max-width: 1300px){
							font-size: 12px;
						}
					}
				}
				.div-atendimento{
					padding-left: 0;
					.atendimento{
						position: relative;
						color: #949494;
						font-size: 14px;
						margin: 0;
						span{
							margin-left: 40px;
						}
						&:before{
							content: '';
							width: 30px;
							height: 6px;
							position: absolute;
							background-color: #FFF7CD;
							border: 1px solid #FFF7CD;
							border-radius: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
						@media (max-width: 1300px){
							font-size: 12px;
						}
					}
				}
				.abertos-ativos,
				.aguardando-ativos,
				.fechamentos-ativos,
				.atendimento-ativos{
					color: $quaternarycolor;
					font-weight: 600;
					font-size: 14px;
					margin-left: 40px;
				}
			}
			.grafico-porcentagem-ativa{
				padding-right: 30px;
				.total-porcentagem{
					display: flex;
					align-items: center;
					color: #C4C4C4;
					font-size: 17px;
					span{
						font-size: 35px;
						font-weight: 700;
						color: $quaternarycolor;
						margin-right: 10px;
					}
				}
				.v-progress-linear{
					margin-top: 30px;
					border-radius: 10px;
				}
				.legendas{
					margin-top: 15px;
					.atendimentos-novos, .atendimentos-fechados{
						padding: 0;
					}
					.novos, .fechados{
						position: relative;
						color: #C4C4C4;
						font-size: 14px;
						margin: 0;
						span{
							margin-left: 40px;
						}
						&:before{
							content: '';
							width: 30px;
							height: 4px;
							position: absolute;
							background-color: #F4C430;
							border: 1px solid #F4C430;
							border-radius: 5px;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					.fechados{
						&:before{
							background-color: #F8DA7F;
							border: 1px solid #F8DA7F;
						}
					}
					.novos-ativos, .fechados-ativos{
						color: $quaternarycolor;
						font-weight: 600;
						font-size: 15px;
						margin-left: 40px;
					}
				}
			}
		}
	}
	
	.grafico-atendimentos canvas{
		max-height: 30vh !important;
	}
</style>