<template>
	<div class="chart">
		<v-card-title>
			<span class="titulo-grafico"> {{title}} <i class="fas fa-chart-line"></i> </span>
		</v-card-title>
		<canvas :id="id" height="250px !important"> </canvas>
		<p class="chamados-realizados" v-if="id == 'graficoPercentual'"><span>{{chamados_realizados}}</span> chamados realizados</p>
	</div>
</template>

<script>
	import Chart from 'chart.js/auto'
	
	export default {
		name: 'Chart',
		data: () => ({
			chart: null
		}),
		props: [
			'id',
			'width',
			'height',
			'type',
			'title',
			'labels',
			'data',
			'fill',
			'tooltips',
			'backgroundColor',
			'borderColor',
			'borderWidth',
			'primarycolor',
			'secondarycolor',
			'chamados_realizados'
		],
		mounted() {
			this.init()
		},
		watch: {
			data:{
				handler (){
					this.chart.destroy()
					this.init()
				},
			},
		},
		methods: {
			init(){
				var ctx = document.getElementById(this.id).getContext('2d')
			
				var scales = {
					xAxes: [{
						gridLines: {
							color: "rgba(0, 0, 0, 0)",
							drawBorder: false,
						}
					}],
					yAxes: [{
						gridLines: {
							color: "rgba(0, 0, 0, 0)",
							drawBorder: false,
						}   
					}]
				}

				var plugins = {
					datalabels: {
						formatter: (value, ctx) => {
							let datasets = ctx.chart.data.datasets
							let sum = null
							let percentage = null
							if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
								sum = datasets[0].data.reduce((a, b) => a + b, 0)
								percentage = Math.round((value / sum) * 100) + '%'
								return percentage
							} else {
								return percentage
							}
						},
					},
					legend: { display: false },		
				}

				var tooltips_ =  {
					callbacks: {
						label: function(tooltipItem, data) {
							return data.labels[tooltipItem.index] + ' ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] 
						}
					}
				}

				var dynamicColors = function() {
					var r = Math.floor(Math.random() * 255)
					var g = Math.floor(Math.random() * 255)
					var b = Math.floor(Math.random() * 255)
					return "rgb(" + r + "," + g + "," + b + ")"
				}

				var colors = []

				for(let i = 0; this.data.length > i; i++){
					colors.push(dynamicColors())
				}

				this.chart = new Chart(
					ctx,
					{
						type: this.type ? this.type : 'bar',
						data: {
							labels: this.labels,
							datasets: [
								{									
									data: this.data,
									label: this.title,
									fill: this.fill ? this.fill : false,
									backgroundColor: this.backgroundColor ? this.backgroundColor : colors,
									pointBorderColor: this.backgroundColor ? this.backgroundColor : colors,
									borderColor: (this.type == 'doughnut' || this.type == 'pie') ? '#fff' : (this.borderColor ? this.borderColor : colors),
									borderWidth: this.borderWidth ? this.borderWidth : 1,
									borderRadius: 10,
								}
							],
						},
						options: {
							scales: (this.type != 'pie' && this.type != 'doughnut') ? scales : {},
							cutoutPercentage: (this.type == 'doughnut') ? 70 : '',
							plugins: (this.type == 'doughnut') ? plugins : '',
							tooltips: this.tootips ? this.tooltips : tooltips_,
							interaction: (this.type != 'doughnut') ? {mode: 'point'} : {mode: 'x'},
						}
					}
				)
			},
			destroy(){
				if(this.id == 'graficoPercentual'){
					let graficoPercentual = Chart.getChart('graficoPercentual')
					if (graficoPercentual != undefined) {
						graficoPercentual.destroy()
						this.init()
					}
				}
			}
		}
	}
</script>

<style lang="scss">
	.chart{
		position: relative;
		height: max-content;
		background-color: #fff;
		padding: 4px;
		border-radius: 10px;
		.v-card_title{
			padding-left: 0;
		}
		.titulo-grafico{
			position: absolute;
			font-weight: 700;
			color: $quaternarycolor;
		}
		h2{
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 28px;
			color: #11263C;
			flex: none;
			order: 0;
			flex-grow: 0;
			margin: 10px 0px;
		}
		canvas{
			width: 100%;
			height: 40% !important;
			z-index: 20;		
		}
		.chamados-realizados{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			text-align: center;
			position: absolute;
			top: 53%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #C4C4C4;
			font-size: 13px;
			z-index: 1;
			span{
				width: 100%;
				color: $quaternarycolor;
				font-size: 35px;
				font-weight: 700;
			}
			@media (max-width: 1390px){
				font-size: 11px;
				span{
					font-size: 30px;
				}
			}
		}
	}
</style>